declare var gsap : any;
declare var $ : any;
declare var Parallax:any;

const tHeader = document.querySelector('#header');
const tBody = document.querySelector('body');

function hShowLang(){
    let languages = tHeader?.querySelector('.lang');
    let sel = <HTMLElement>languages?.querySelector('.lang-sel');
    let opt = <HTMLElement>languages?.querySelector('.lang-opt');

    sel?.addEventListener('click', function(e){
        e.preventDefault();
        this.classList.toggle('showed');
        if (this.classList.contains('showed')){
            opt.style.animation = "showLangOpts 0.16s ease-in-out"
        }else{
            opt.style.animation = "";
        }
    })

    document.addEventListener('click', function(e){
        if (sel?.classList.contains('showed')){
            let targetE = <HTMLElement>e.target;
            let iWhile = 1;
            if (!targetE.classList.contains('lang')){
                do{
                    targetE = targetE.parentElement;
                    if (iWhile >= 100 || !targetE || targetE.classList.contains('lang') == true){
                        break;
                    }
                    ++iWhile;
                }while(targetE.classList.contains('lang') == false)
            }
    
            if (!targetE?.classList.contains('lang')){
                sel?.classList.remove('showed');
            }
        }
    })
}

function hShowNavbarMobile(){
    let mbNav = tHeader?.querySelector('.mobile-navbar');
    let sel = <HTMLElement>mbNav?.querySelector('.mobile-navbar-sel');
    let opt = <HTMLElement>mbNav?.querySelector('.mobile-navbar-opt');
    let close = <HTMLElement>opt?.querySelector('.close');

    setInterval(function(){
        if(sel?.classList.contains('showed')){
            tBody.classList.add('hideScroll');
        }else{
            tBody.classList.remove('hideScroll');
        }
    }, 300)

    sel?.addEventListener('click', function(e){
        e.preventDefault();
        this.classList.toggle('showed');
        if (this.classList.contains('showed')){
            opt.style.animation = "showMobileNav 1.2s ease-in-out"
        }else{
            opt.style.animation = "";
        }
    })

    close?.addEventListener('click', function(e){
        e.preventDefault();
        sel?.classList.remove('showed');
        opt.style.animation = "";
    })

    document.addEventListener('click', function(e){
        if (sel?.classList.contains('showed')){
            let targetE = <HTMLElement>e.target;
            let iWhile = 1;
            if (!targetE.classList.contains('mobile-navbar')){
                do{
                    targetE = targetE.parentElement;
                    if (iWhile >= 100 || !targetE || targetE.classList.contains('mobile-navbar') == true){
                        break;
                    }
                    ++iWhile;
                }while(targetE.classList.contains('mobile-navbar') == false)
            }
    
            if (!targetE?.classList.contains('mobile-navbar')){
                sel?.classList.remove('showed');
            }
        }
    })
}


export const header = function(){
    hShowLang();
    hShowNavbarMobile();
}



