declare var window: any;
declare var gsap: any;
declare var ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);

export const _header = function () {
    var lastScrollTop = 0;
    var timeout: any;

    function callbackTimeout(){
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
            // downscroll code
            document.querySelector('.header').classList.remove('scrollUp');
        } else {
            // upscroll code
            if (window.scrollY === 0){
                document.querySelector('.header').classList.remove('scrollUp');
            }else{
                document.querySelector('.header').classList.add('scrollUp');
            }
        }
        lastScrollTop = st <= 0 ? 0 : st;
    }
    
    document.addEventListener("scroll", function () {
        clearTimeout(timeout);
        timeout = setTimeout(callbackTimeout, 200);
    }, false);

}