declare var window:any;

export const news = function(){
    mouseBoxHandle();
}

function mouseBoxHandle(){
    // let boxes = document.querySelectorAll('.mouse-handle');
    if (document.querySelector('.news-box')){
        window.mouseNewsHandle()
    }
}