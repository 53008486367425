declare var window:any;
declare var Swiper : any;
declare var $ : any;
declare var gsap : any;
declare var ScrollTrigger: any;

export const services = function(){
    slickSv1();
    handleClick();
}

function handleClick(){
    const svsSliderItems = document.querySelectorAll('.svs-1-slider .swiper-slide');
    svsSliderItems.forEach(function(itemSlide){
        let item = itemSlide.querySelector('.item');
        let link = <HTMLElement>item?.querySelector('.title a');
        console.log(item);
        
        if (link){
            item.addEventListener('click', function(e){
                if (itemSlide.classList.contains('swiper-slide-active')){
                    if (e.target !== link){
                        console.log(false);
                        e.preventDefault();
                    }
                    link.click();
                }else{
                    if (e.target !== link){
                        e.preventDefault();
                    }
                }
            })
        }

    })
}

function slickSv1(){
    let sw = new Swiper(".svs-1-slider", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        // loop: true,
        disableOnInteraction: false,
        reverseDirection: true,
        slidesPerView: "auto",
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        speed: 1400,
        coverflowEffect: {
          rotate: 3,
          stretch: 0,
          depth: 80,
          modifier: 1,
          slideShadows: false,
        },
    })
}