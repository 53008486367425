declare var window: any;
declare var $: any;

export const _base = function () {
	window.delHandle = function (list: any, str: any) {
		if (typeof str == "string") {
			list.forEach(function (item: HTMLElement) {
				item.classList.remove(str);
			});
		}
	};
	window.getParameters = function () {
		let urlSearchParams = new URLSearchParams(window.location.search);
		let params = Object.fromEntries(urlSearchParams.entries());

		return params;
	};

	window.getQueryString = function (params: any) {
		var str = Object.keys(params)
			.map(function (key) {
				return key + "=" + params[key];
			})
			.join("&");
		return str;
	};

	window.setPushState = function (name: any, value: any, url: any) {
		let params = window.getParameters();
		params[name] = value;
		let queryStr = window.getQueryString(params);
		window.history.pushState("", "", "?" + queryStr);
	};

	// run number
	window.runNumberJs = function (idSection: string, spaceNumber: number = 0) {
		var page = document.getElementById(idSection);
		if (page) {
			// $(`#${idSection} .number`).hide();

			let scrollId = document.querySelectorAll(`#${idSection} .number`);

			function numberWithCommas(x: any) {
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
			}

			if ($(window).scrollTop() >= $(`#${idSection} .number`).offset().top - ($(window).height() - spaceNumber)){
				if (!$(`#${idSection} .number`).hasClass("animated")) {
					$(`#${idSection} .number`).each(function () {
						let curNum = $(this).text();
						$(this)
							.prop("Counter", 0)
							.animate(
								{
									Counter: $(this).text(),
								},
								{
									duration: 4000,
									easing: "swing",
									step: function (now: any) {
										let num = Math.ceil(now) + "";
										if (parseInt(num) < 10) {
											num = "0" + parseInt(num);
										}

										$(this).text(numberWithCommas(num));

										if (curNum == num) {
											$(this).addClass("successed");
										}
									},
								},
							);
					});
					// $("#triggered").addClass("show");
					$(`#${idSection} .item`).addClass("show");
					$(`#${idSection} .number`).addClass("animated");
				}
			}

			$(window).on("scroll", function (e: any) {
				if ($(window).scrollTop() >= $(`#${idSection} .number`).offset().top - ($(window).height() - spaceNumber)){
					if (!$(`#${idSection} .number`).hasClass("animated")) {
						$(`#${idSection} .number`).each(function () {
							let curNum = $(this).text();
							$(this)
								.prop("Counter", 0)
								.animate(
									{
										Counter: $(this).text(),
									},
									{
										duration: 4000,
										easing: "swing",
										step: function (now: any) {
											let num = Math.ceil(now) + "";
											if (parseInt(num) < 10) {
												num = "0" + parseInt(num);
											}

											$(this).text(numberWithCommas(num));

											if (curNum == num) {
												$(this).addClass("successed");
											}
										},
									},
								);
						});
						// $("#triggered").addClass("show");
						$(`#${idSection} .item`).addClass("show");
						$(`#${idSection} .number`).addClass("animated");
					}
				}
			});
		}
	};

	// run step number
	window.stepNumber = function (finalNumber: number, duration = 2000, start = 0, callback: Function){
		const startTime = performance.now() + start;

		function step(currentTime:any) {
			const elapsedTime = currentTime - startTime;

			if (elapsedTime > duration) {
			  callback(finalNumber);
			} else {
			  const rate = elapsedTime / duration
			  const currentNumber = Math.round(rate * finalNumber)
			  callback(currentNumber)
			  requestAnimationFrame(step)
			}
		}

		requestAnimationFrame(step);
	}

	// mouse in seemore box
	window.mouseBoxHandle = function (idSection: string, strHandle: string) {
		let box = document.getElementById(idSection);
		if (!box) {
			return;
		}
		let children = box.children;
		let mouseHandle = <HTMLElement>box?.querySelector(strHandle);
		let checkClick = false;
		let interval: any;

		// if (window.innerWidth < 1024){
		//   checkClick = true;
		// }

		for (var i = 0; i < children.length; ++i) {
			let ele = <HTMLElement>children[i];
			if (ele.classList.contains("item")) {
				ele.setAttribute("dt-item", i.toString());
			}
		}

		box.style.position = "relative";
		box.addEventListener("mousemove", function (evt) {
			let targetE = <HTMLElement>evt.target;
			if (targetE.classList.contains("item")) {
				let iItem = parseInt(targetE.getAttribute("dt-item"));
				mouseHandle.style.display = "inline-flex";
				let x = evt.offsetX / (window.innerWidth / 100);
				let y =
					(evt.offsetY + targetE.offsetHeight * (iItem - 1)) /
					(window.innerHeight / 100);
				mouseHandle.style.left = x + "vw";
				mouseHandle.style.top = y + "vh";
			} else {
				mouseHandle.style.display = "";
			}
			if (!checkClick) {
				setInterval(function () {
					checkClick = true;
				}, 500);
			}
		});

		box.addEventListener("mouseleave", function (evt) {
			mouseHandle.style.display = "";
			clearInterval(interval);
			checkClick = false;
		});

		for (var i = 0; i < children.length; ++i) {
			let ele = <HTMLElement>children[i];
			if (ele.classList.contains("item")) {
				ele.addEventListener("click", function (e) {
					if (!checkClick) {
						e.preventDefault();
					}
				});
			}
		}
	};

	
	window.mouseProjectHandle = function (listItem: string, strHandle: string) {
		// var boxes = document.querySelectorAll(listItem);
		var mousePos:any;
		var checked = false;

		function handleMouseMove(event:any){
			var dot, eventDoc, doc, body, pageX, pageY;
			event = event || window.event; // IE-ism
			// If pageX/Y aren't available and clientX/Y are,
			// calculate pageX/Y - logic taken from jQuery.
			// (This is to support old IE)
			if (event.pageX == null && event.clientX != null) {
				eventDoc = (event.target && event.target.ownerDocument) || document;
				doc = eventDoc.documentElement;
				body = eventDoc.body;
	
				event.pageX = event.clientX +
				  (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
				  (doc && doc.clientLeft || body && body.clientLeft || 0);
				event.pageY = event.clientY +
				  (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
				  (doc && doc.clientTop  || body && body.clientTop  || 0 );
			}
	
			mousePos = {
				x: event.clientX,
				y: event.clientY
			};

			let target = event.target;
			// console.log(boxes);
			if (target.parentElement.classList.contains('project-box')){
				checked = true;	
			}
			else{
				do{
					target = target.parentElement;
	
					if (target.parentElement == null){
						checked = false;
						break;
					}
					
					if (target.parentElement.classList.contains('project-box')){
						checked = true;
						return;
					}
				}while(target.parentElement !== null)
				checked = false;
			}
		}
		
		function getMousePosition() {
			var pos = mousePos;
			if (!pos) {
				// We haven't seen any movement yet
			}
			else {
				// Use pos.x and pos.y
				var divMouse = <HTMLElement>document.querySelector('.mouse-handle');
				divMouse.style.top = mousePos.y + "px";
				divMouse.style.left = mousePos.x + "px";
				if (checked){
					divMouse.classList.add('visible');
				}else{
					divMouse.classList.remove('visible');
				}
			}
		}
		document.onmousemove = handleMouseMove;
		setInterval(getMousePosition); 
	};
	window.mouseNewsHandle = function () {
		// var boxes = document.querySelectorAll(listItem);
		var mousePos:any;
		var checked = false;

		function handleMouseMove(event:any){
			var dot, eventDoc, doc, body, pageX, pageY;
			event = event || window.event; // IE-ism
			// If pageX/Y aren't available and clientX/Y are,
			// calculate pageX/Y - logic taken from jQuery.
			// (This is to support old IE)
			if (event.pageX == null && event.clientX != null) {
				eventDoc = (event.target && event.target.ownerDocument) || document;
				doc = eventDoc.documentElement;
				body = eventDoc.body;
	
				event.pageX = event.clientX +
				  (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
				  (doc && doc.clientLeft || body && body.clientLeft || 0);
				event.pageY = event.clientY +
				  (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
				  (doc && doc.clientTop  || body && body.clientTop  || 0 );
			}
	
			mousePos = {
				x: event.clientX,
				y: event.clientY
			};

			let target = event.target;
			// console.log(boxes);
			if (target.parentElement.classList.contains('news-box')){
				checked = true;	
			}else{
				do{
					target = target.parentElement;
	
					if (target.parentElement == null){
						checked = false;
						break;
					}
					
					if (target.parentElement.classList.contains('news-box')){
						checked = true;
						return;
					}
				}while(target.parentElement !== null)
				checked = false;
			}
		}
		
		function getMousePosition() {
			var pos = mousePos;
			if (!pos) {
				// We haven't seen any movement yet
			}
			else {
				// Use pos.x and pos.y
				var divMouse = <HTMLElement>document.querySelector('.mouse-handle');
				divMouse.style.top = mousePos.y + "px";
				divMouse.style.left = mousePos.x + "px";
				if (checked){
					divMouse.classList.add('visible');
				}else{
					divMouse.classList.remove('visible');
				}
			}
		}
		document.onmousemove = handleMouseMove;

		setInterval(getMousePosition); 
	};
};
