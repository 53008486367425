declare var window:any;
declare var pagination:any;

declare var gsap: any;
declare var ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);
let tl = gsap.timeline();

export const project = function(){
    if (document.querySelector('#projectPage')){
        activeHandle1();
        mouseBoxHandle();
    
        gridProject();
    }
}

function activeHandle1(){
    let navLs = document.querySelectorAll('.p-1 .nav-bar li');
    let wrapperLs = <HTMLElement> document.querySelector('.p-1-wrapper .p-1-project');
    const cpWrapper = [...wrapperLs.children];

    cpWrapper.forEach(function(itemCp, idx){
        let link = <HTMLElement>itemCp.querySelector('a');

        itemCp.addEventListener('click', function(e){
            e.stopPropagation();
            link.click();
        })
    })

    wrapperLs.innerHTML = "";

    const _STYLE = 'dt-style';
    const _ACTIVE_LI = "active";

    let lsstyle:any = [];
    navLs.forEach(function(item, idx){
        if (idx !== 0 && idx !== navLs.length - 1){
            lsstyle.push(item.getAttribute(_STYLE));
        }
    })
    
    navLs.forEach(function(itemNav, idx){
        if(itemNav.classList.contains(_ACTIVE_LI)){
            let itemNavStyle = itemNav.getAttribute(_STYLE);
            let i = 0;
            if (idx === 0){
                cpWrapper.forEach(function(item:HTMLElement){
                    item.classList.add('expand');
                    i = iGrid(item, i);
                    
                    wrapperLs.appendChild(item);
                })
            }else if (idx === navLs.length - 1){
                cpWrapper.forEach(function(item){
                    let style = item.getAttribute(_STYLE);
                    if (!lsstyle.includes(style)){
                        item.classList.add('expand');
                        i = iGrid(item, i);
                        wrapperLs.appendChild(item);
                    }
                })
            }
            else{
                cpWrapper.forEach(function(item){
                    let style = item.getAttribute(_STYLE);
                    
                    if (lsstyle.includes(style)){
                        if (itemNavStyle === style){
                            item.classList.add('expand');
                            i = iGrid(item, i);
                            wrapperLs.appendChild(item);
                        }
                    }
                })
            }

            pageProject();
        }

        itemNav.addEventListener('click', function(e){
            e.preventDefault();
            // wrapperLs.innerHTML = "";

            let _this = this;
            navLs.forEach(function(temp){
                temp.classList.remove(_ACTIVE_LI);
            })
            _this.classList.add(_ACTIVE_LI);

            let itemNavStyle = _this.getAttribute(_STYLE);

            window.delHandle(cpWrapper, 'expand');

            let i = 0;

            if (idx === 0){;
                cpWrapper.forEach(function(item:HTMLElement){
                    item.classList.add('expand');
                    i = iGrid(item, i);
                    wrapperLs.appendChild(item);
                })
            }
            else if (idx === navLs.length - 1){
                cpWrapper.forEach(function(item){
                    let style = item.getAttribute(_STYLE);
                    if (!lsstyle.includes(style)){
                        item.classList.add('expand');
                        i = iGrid(item, i);
                        wrapperLs.appendChild(item);
                    }
                })
            }
            else{
                cpWrapper.forEach(function(item){
                    let style = item.getAttribute(_STYLE);

                    if (lsstyle.includes(style)){
                        if (itemNavStyle === style){

                            console.log(item);

                            item.classList.add('expand');
                            i = iGrid(item, i);
                            wrapperLs.appendChild(item);
                        }
                    }
                })
            }

            pageProject();
        })
    })    

}

function iGrid(el:any, idx:any){
    if (idx % 12 == 0){
        el.style.gridColumn = "1 / span 2";
        el.style.gridRow = "1 / span 3";
    }else if (idx % 12 == 1){
        el.style.gridColumn = "3 / span 1";
        el.style.gridRow = "3 / span 2";
    }
    else if (idx % 12 == 2){
        el.style.gridColumn = "2 / span 1";
        el.style.gridRow = "4 / span 2";
    }
    else if (idx % 12 == 3){
        el.style.gridColumn = "1 / span 1";
        el.style.gridRow = "5 / span 2";
    }
    else if (idx % 12 == 4){
        el.style.gridColumn = "2 / span 2";
        el.style.gridRow = "6 / span 3";
    }

    else if (idx % 12 == 5){
        el.style.gridColumn = "1 / span 1";
        el.style.gridRow = "8 / span 2";
    }
    else if (idx % 12 == 6){
        el.style.gridColumn = "2 / span 1";
        el.style.gridRow = "9 / span 2";
    }
    else if (idx % 12 == 7){
        el.style.gridColumn = "3 / span 1";
        el.style.gridRow = "10 / span 2";
    }
    else if (idx % 12 == 8){
        el.style.gridColumn = "1 / span 2";
        el.style.gridRow = "11 / span 3";
    }
    else if (idx % 12 == 9){
        el.style.gridColumn = "3 / span 1";
        el.style.gridRow = "13 / span 2";
    }
    else if (idx % 12 == 10){
        el.style.gridColumn = "2 / span 1";
        el.style.gridRow = "14 / span 2";
    }
    else if (idx % 12 == 11){
        el.style.gridColumn = "1 / span 1";
        el.style.gridRow = "15 / span 2";
    }

    else{
        el.style.gridColumn = "span 1";
        el.style.gridRow = "span 2";

    }

    return ++idx;
}

function mouseBoxHandle(){
    // let boxes = document.querySelectorAll('.mouse-handle');
    if (document.querySelector('.p-1-wrapper .item')){
        window.mouseProjectHandle('.p-1-wrapper .item', '.mouse-handle')
    }
}

function pageProject(){
    new pagination({
        selector: `.p-1-pagination .pagination`,
        items: `.p-1-wrapper .expand`,
        page: 12,
        scroll: {
            id: "navProject"
        }
    });
}

function gridProject(){
    // tl.clear();

    // document.querySelectorAll(strQ).forEach(function(item:any, idx:any){
    //     if (idx % 4 == 0){
    //         if (idx % 8 == 0){
    //             gsap.from(item, {
    //                 opacity: 0,
    //                 x: -200,
    //                 scrollTrigger: {
    //                     trigger: item,
    //                     toggleActions: "play none none none", start: "top bottom", end: "bottom top"
    //                 },
    //                 duration: 1.2,
    //             })
    //         }else{
    //             gsap.from(item, {
    //                 opacity: 0,
    //                 x: 200,
    //                 scrollTrigger: {
    //                     trigger: item,
    //                     toggleActions: "play none none none", start: "top bottom", end: "bottom top"
    //                 },
    //                 duration: 1.2,
    //             })
    //         }
    //     }
    //     else{
    //         gsap.from(item, {
    //             opacity: 0,
    //             y: 200,
    //             scrollTrigger: {
    //                 trigger: item,
    //                 toggleActions: "play none none none", start: "top bottom", end: "bottom top"
    //             },
    //             duration: 1.2,
    //         })
    //     }
    // })

}