export const boxProcedure = function(){
    initProcedure();
}

function initProcedure (){
    let pros = document.querySelectorAll('.procedure-box .box-item');
    pros.forEach(function(item){
        item.addEventListener('click', function(){
            if (!item.classList.contains('hide')){
                item.classList.add('hide');
            }
            else{
                pros.forEach(function(temp){
                    temp.classList.add('hide');
                })
    
                item.classList.remove('hide');
            }
        })
    })
}