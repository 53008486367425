declare var window: any;

export const initPage = function () {
	let pagination: any;

	pagination = function (opts: any) {
		this.options = Object.assign(pagination.defaults, opts);
		this.init(this.options);
	};

	pagination.prototype.init = function (opts: any) {
		this.options = opts ? Object.assign(this.options, opts) : this.options;
		var plugin = this;
		let options = plugin.options;
		let paramItem = options.param;

		// queryPagination: string, queryBox: string
		var pages = <HTMLElement>document.querySelector(`${options.selector}`);
		
		pages.classList.remove('none');

		var totalBox = document.querySelectorAll(options.items);
		var totalPage = Math.ceil(totalBox.length / options.page);
		plugin.activeBox(totalBox, options.page, 1);
		
		if (!pages || !totalBox || totalPage == 1) {
			pages.classList.add('none')
			pages.innerHTML = "";
			return;
		}

		var scrollTop = options.scroll;

		if (scrollTop) {
			plugin.paginationCreate(
				pages,
				totalBox,
				1,
				totalPage,
				options.page,
				scrollTop,
				paramItem,
			);
		} else {
			plugin.paginationCreate(
				pages,
				totalBox,
				1,
				totalPage,
				options.page,
				paramItem,
			);
		}

		if (paramItem){
			let params = window.getParameters();
			let page = parseInt(params[paramItem]);
		
			if (!page || page < 1) {
				page = 1;
			}
			plugin.activeItem(
				page,
				pages,
				totalBox,
				totalPage,
				options.page,
				scrollTop,
				paramItem,
			);
		
			window.addEventListener("popstate", function (event: any) {
				let params = window.getParameters();
				let page = parseInt(params[paramItem]);
		
				if (!page || page < 1) {
					page = 1;
				}
		
				plugin.activeItem(
					page,
					pages,
					totalBox,
					totalPage,
					options.page,
					scrollTop,
					paramItem,
				);
			});
		}
	};

	pagination.prototype.paginationCreate = function (
		objClass: any,
		totalBox: any,
		liActive: number,
		totalPage: number,
		itemPages: number,
		scrollTop: any = null,
		paramItem: any = null,
	) {
		// Math.ceil(totalBox.length / 5)
		var plugin = this;
		objClass.innerHTML = this.createPagination(totalPage, liActive);

		var strIcon = `<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.33198 0L0 1.41L4.32659 6L0 10.59L1.33198 12L7 6L1.33198 0Z" fill="black"/>
                        </svg>`;
						
		if (totalPage == 1){
			return;
		}

		// thay đổi kí tự next và prev
		plugin.changeIcon(objClass, "next", strIcon);
		plugin.changeIcon(objClass, "prev", strIcon);

		// click button
		if (scrollTop) {
			plugin.clickButton(
				objClass,
				totalBox,
				totalPage,
				itemPages,
				scrollTop,
				paramItem,
			);
		} else {
			plugin.clickButton(
				objClass,
				totalBox,
				totalPage,
				itemPages,
				paramItem,
			);
		}
	};

	pagination.prototype.createPagination = function (
		totalPages: any,
		page: any,
	) {
		let strStart = `<li class="prev"><a href="javascript:void(0)">prev</a></li>`;
		let strList = "";

		// khi tổng số page nhỏ hơn 5
		if (totalPages == 1) {
			return "";
		} else if (totalPages < 5) {
			for (var i = 1; i <= totalPages; i++) {
				if (i == page) {
					strList += `<li class="active" data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
				} else {
					strList += `<li data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
				}
			}
		} else if (
			(page <= 3 || page >= totalPages - 2) &&
			page > 0 &&
			page <= totalPages
		) {
			// trường hợp active tạo thành 1 2 3 ... 20
			if (page <= 3) {
				if (page == 3 && totalPages == 5) {
					for (var i = 1; i <= totalPages; i++) {
						if (i == page) {
							strList += `<li class="active" data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
						} else {
							strList += `<li data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
						}
					}
				} else {
					var visiblePage = page + 1;
					if (page == 1) {
						visiblePage = 3;
					}
					for (var i = 1; i <= visiblePage; i++) {
						if (i == page) {
							strList += `<li class="active" data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
						} else {
							strList += `<li data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
						}
					}
					strList += `<li class="dot">...</li>`;
					strList += `<li data-number="${totalPages}"><a href="javascript:void(0)">${totalPages}</a></li>`;
				}
			}
			// trường hợp active tạo thành 1 ... 18 19 20
			else {
				strList += `<li data-number="1"><a href="javascript:void(0)">1</a></li>`;
				strList += `<li class="dot">...</li>`;

				var visiblePage2 = page - 1;
				if (page == totalPages) {
					visiblePage2 = totalPages - 2;
				}

				for (var i = visiblePage2; i <= totalPages; i++) {
					if (i == page) {
						strList += `<li class="active" data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
					} else {
						strList += `<li data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
					}
				}
			}
		}
		// trường hợp active tạo thành 1 ... 7 8 9 ... 20
		else if (page > 3 && page < totalPages - 2) {
			strList += `<li data-number="1"><a href="javascript:void(0)">1</a></li>`;
			strList += `<li class="dot">...</li>`;
			for (var i = page - 1; i <= page + 1; i++) {
				if (i == page) {
					strList += `<li class="active" data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
				} else {
					strList += `<li data-number="${i}"><a href="javascript:void(0)">${i}</a></li>`;
				}
			}
			strList += `<li class="dot">...</li>`;
			strList += `<li data-number="${totalPages}"><a href="javascript:void(0)">${totalPages}</a></li>`;
		} else {
			console.log("error pagination!!!");
			return "";
		}

		let strEnd = `<li class="next"><a href="javascript:void(0)">next</a></li>`;
		return strStart.concat(strList, strEnd);
	};

	pagination.prototype.activeBox = function (
		totalBox: any,
		itemPages: number,
		liActive: any,
	) {
		totalBox.forEach(function (item: HTMLElement) {
			item.style.display = "";
		});

		totalBox.forEach(function (item: HTMLElement, index: number) {
			var itemPage = Math.ceil((index + 1) / itemPages);
			if (itemPage !== liActive) {
				item.style.display = "none";
			}
		});
	};

	pagination.prototype.clickButton = function (
		objClass: any,
		totalBox: any,
		totalPage: number,
		itemPages: number,
		scrollTop: any = null,
		paramItem: any,
	) {
		this.itemActive = 0;
		var plugin = this;
		let liBtns = objClass.getElementsByTagName("li");

		// let itemActive = 0;

		// lấy giá trị được active
		for (let i = 0; i < liBtns.length; i++) {
			let item = <HTMLElement>liBtns[i];
			if (item.classList.contains("active")) {
				plugin.itemActive = parseInt(
					item.getAttribute("data-number") || "0",
				);
			}
		}

		// Không cho click vào button Next or Prev nếu active gần nó
		plugin.hiddenNextPrev(
			objClass,
			plugin.itemActive,
			totalPage,
		);

		for (let i = 0; i < liBtns.length; i++) {
			let item = liBtns[i];

			item.onclick = function () {
				// let classItem = this.getAttribute('class');
				let dataItem = parseInt(this.getAttribute("data-number"));
				if (scrollTop) {
					plugin.scrollToTop(
						scrollTop.id,
						scrollTop.header,
						paramItem,
					);
				}

				if (
					this.classList.contains("btn-hidden") ||
					dataItem == plugin.itemActive
				) {
					return;
				}
				if (
					this.classList.contains("next") &&
					plugin.itemActive < totalPage
				) {
					plugin.itemActive = plugin.itemActive + 1;
				} else if (
					this.classList.contains("prev") &&
					plugin.itemActive > 1
				) {
					plugin.itemActive = plugin.itemActive - 1;
				} else {
					if (!dataItem) {
						return;
					}
					plugin.itemActive = dataItem;
				}

				plugin.hiddenNextPrev(objClass, plugin.itemActive, totalPage);
				plugin.paginationCreate(
					objClass,
					totalBox,
					plugin.itemActive,
					totalPage,
					itemPages,
					scrollTop,
					paramItem,
				);
				plugin.activeBox(
					totalBox,
					itemPages,
					plugin.itemActive
				);



				if(paramItem){
					window.setPushState(paramItem, plugin.itemActive);
				}
					// let params = window.getParameters();
					// params[paramItem] = plugin.itemActive;
					// let queryStr = window.getQueryString(params);
					// window.history.pushState("", "", "?" + queryStr);
			};
		}
	};

	pagination.prototype.activeItem = function (
		iNumber: any,
		objClass: any,
		totalBox: any,
		totalPage: number,
		itemPages: number,
		scrollTop: any = null,
		paramItem: any,
	) {
		var plugin = this;
		var itemActive = plugin.itemActive;

		let liBtns = objClass.getElementsByTagName("li");
		if (liBtns.length <= 0) {
			console.log("error click!!!!");
			return;
		}
		let item = liBtns[0];

		for (let i = 0; i < liBtns.length; i++) {
			let num = parseInt(liBtns[i].getAttribute("data-number"));
			if (num && num == iNumber) {
				item = liBtns[i];
				break;
			}
		}

		// let classItem = this.getAttribute('class');
		let dataItem = parseInt(item.getAttribute("data-number"));

		if (item.classList.contains("btn-hidden") || dataItem == itemActive) {
			return;
		}
		if (item.classList.contains("next") && itemActive < totalPage) {
			itemActive = itemActive + 1;
		} else if (item.classList.contains("prev") && itemActive > 1) {
			itemActive = itemActive - 1;
		} else {
			if (!dataItem) {
				return;
			}
			itemActive = dataItem;
		}

		// this.hiddenNextPrev(objClass, itemActive, totalPage);
		plugin.paginationCreate(
			objClass,
			totalBox,
			itemActive,
			totalPage,
			itemPages,
			scrollTop,
			paramItem,
		);
		plugin.activeBox(totalBox, itemPages, itemActive);

		if (scrollTop) {
			plugin.scrollToTop(scrollTop.id, scrollTop.header, paramItem);
		}
	};

	pagination.prototype.scrollToTop = function (
		strId: string,
		queryHeader: string = "",
		paramItem: any,
	) {
		var plugin = this;

		if (document.getElementById(strId)) {
			var topFeatured = 0;
			var featuredItem = <HTMLElement>document.getElementById(strId);
			if (featuredItem) {
				var parentFeatured = <HTMLElement>featuredItem.offsetParent;
				var count = 0;
				while (parentFeatured.nodeName !== "BODY" && count < 100) {
					topFeatured += parentFeatured.offsetTop;
					parentFeatured = <HTMLElement>parentFeatured.offsetParent;
					count++;
				}
				topFeatured += featuredItem.offsetTop;
			}

			// // Trừ đi thanh Header
			// var hHeader = document.querySelector(queryHeader);
			// if (hHeader){
			//   var scroll = hHeader.getElementsByClassName('scroll');
			//   if (scroll[0].classList.contains('active')){
			//     topFeatured -= scroll[0].clientHeight;
			//   }
			//   else if (hHeader){
			//     topFeatured -= hHeader.clientHeight;
			//   }
			// }

			window.scrollTo({
				top: topFeatured,
				left: 0,
				behavior: "smooth",
			});
		}
	};

	pagination.prototype.changeIcon = function (
		objClass: any,
		strClass: string,
		strIcon: string
	) {
		var classStrChange = objClass.getElementsByClassName(strClass);
		var innerStrClass = <HTMLElement>classStrChange[0];

		innerStrClass.innerHTML = `<a href="javascript:void(0)">${strIcon}</a>`;
	};

	pagination.prototype.hiddenNextPrev = function (
		objClass: any,
		itemActive: number,
		totalPage: number
	) {
		let liBtns = objClass.getElementsByTagName("li");

		let itemNext;
		let itemPrev;

		// lấy giá trị được active
		for (let i = 0; i < liBtns.length; i++) {
			let item = <HTMLElement>liBtns[i];
			if (item.classList.contains("next")) {
				itemNext = item;
			} else if (item.classList.contains("prev")) {
				itemPrev = item;
			}
		}

		// Không cho click vào button Next or Prev nếu active gần nó
		if (totalPage === 1) {
			itemPrev.classList.add("btn-hidden");
			itemNext.classList.add("btn-hidden");
		} else if (itemActive === 1) {
			itemPrev.classList.add("btn-hidden");
			itemNext.classList.remove("btn-hidden");
		} else if (itemActive === totalPage) {
			itemPrev.classList.remove("btn-hidden");
			itemNext.classList.add("btn-hidden");
		} else {
			itemPrev.classList.remove("btn-hidden");
			itemNext.classList.remove("btn-hidden");
		}
	};

	pagination.defaults = {
		id: "",
		param: undefined,
		selector: "",
		items: "",
		page: 0,
		scroll: {
			id: "",
		},
	};

	window.pagination = pagination;
};
