declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var gsap: any;
declare var ScrollTrigger: any;

export const index = function () {
	slickIdx5();
	slickIdx6();

	// gsapInit();
};

function slickIdx5() {
	$(".idx-5 .slider").slick({
		// draggable:false,
		// infinite: true,
		// slidesToShow: 4,
		// slidesToScroll: 4,
		// rows: 2,

		// adaptiveHeight: true,

		// autoplay: true,
		// autoplaySpeed: 1200,
		// speed:6000,

		// appendDots: '.dots',
		// arrows: false,

		// cssEase: 'ease-in-out',
		// variableWidth:true,

		draggable: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		rows: 2,

		infinite: true,
		arrows: false,
		initialSlide: 0,

		autoplay: true,
		autoplaySpeed: 0,
		speed: 6000,

		cssEase: "linear",
		pauseOnHover: false,
		rtl: false,

		dots: true,
		// variableWidth:true,
		// respondTo:window,

		responsive: [
			{
				breakpoint: 992,
				settings: {
					infinite: true,
					arrows: false,
					initialSlide: 0,

					slidesToShow: 3,
					slidesToScroll: 3,

					autoplay: true,
					autoplaySpeed: 0,
					speed: 4600,

					cssEase: "linear",
					pauseOnHover: false,
					rtl: false,
				},
			},
			{
				breakpoint: 768,
				settings: {
					infinite: true,
					arrows: false,
					initialSlide: 0,

					slidesToShow: 2,
					slidesToScroll: 2,

					autoplay: true,
					autoplaySpeed: 0,
					speed: 3000,

					cssEase: "linear",
					pauseOnHover: false,
					rtl: false,
				},
			},
		],
	});
}
function slickIdx6() {
	$(".idx-6 .box").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		rows: 6,

		infinite: true,
		arrows: false,
		dots: true,

		autoplay: true,
		autoplaySpeed: 4000,
		speed: 1000,
		fade: true,

		cssEase: "ease-in-out",
		pauseOnHover: true,
		rtl: false,

		adaptiveHeight: true,
	});
}