declare var window: any;
declare var Swiper: any;
declare var $: any;
declare var ROUNDDOWN: any;

export const about = function () {
    window.runNumberJs("dataYears", 300);
    // swab2();
    swab3();
    slickab4();
    swab5();
    slickab6();

    // scrollBar();
    scrollab2();
}

function swab3() {
    let sw = new Swiper(".ab-3 .team-box", {
        // loop: true,
        disableOnInteraction: false,
        reverseDirection: true,
        slidesPerView: "auto",
        spaceBetween: 11,
        loop: true,
        autoplay: {
            delay: 2000,
        },
        speed: 1400,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    })
}

function swab5() {
    let sw = new Swiper(".ab-5 .certify-box", {
        // loop: true,
        disableOnInteraction: false,
        reverseDirection: true,
        slidesPerView: "auto",
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 1200,
        },
        speed: 900,

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    })
}

function slickab4() {
    $(".ab-4 .slider").slick({
        draggable: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        speed: 6000,
        cssEase: 'linear',
        variableWidth: true,
        initialSlide: 0,
        pauseOnHover: false,
        respondTo: window,
        rtl: false,
        freeMode: true,
    });
}
function slickab6() {
    $(".ab-6 .slider").slick({
        draggable: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        speed: 6000,
        cssEase: 'linear',
        variableWidth: true,
        initialSlide: 0,
        pauseOnHover: false,
        respondTo: window,
        rtl: false,
        freeMode: true,
    });
}


function scrollab2() {
    let scrBraney = document.querySelectorAll('.braney-scroll');
    scrBraney.forEach(function (scroll) {
        let bar = scroll.querySelector('.scroll-bar');

        let thumb = document.createElement('span');
        thumb.classList.add('thumb');
        bar.appendChild(thumb);

        let content = <HTMLElement>document.querySelector('.braney-scroll-content');
        let contentItems = content?.querySelectorAll('.item');

        let arrHeight = new Array(contentItems.length);

        contentItems.forEach(function (item: HTMLElement, idx) {
            let offTop = item.offsetTop;
            let temp = <HTMLElement>item.offsetParent;
            do {
                if (temp) {
                    offTop += temp.offsetTop;
                    temp = <HTMLElement>temp.offsetParent;
                }
            } while (temp)
            arrHeight[idx] = {
                top: offTop,
                height: item.clientHeight
            }
        })

        let timeout: any;
        window.addEventListener('scroll', function (evt: any) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                let y = window.scrollY;
                for (var idx = 0; idx < arrHeight.length; idx++) {
                    if (y <= arrHeight[idx].top) {
                        if (idx == 0) {
                            thumb.style.top = `calc(var(--scrollW)/ 3 + 0px)`;
                            thumb.style.transform = `translate(-50%, 0%)`;
                            break;
                        }
                        else if (idx == arrHeight.length - 1) {
                            thumb.style.top = `calc(100% - (var(--scrollW)/ 3))`;
                            thumb.style.transform = `translate(-50%, -100%)`;
                            break;
                        }
                        else {
                            let sum = 0;
                            for (var i = 0; i < idx; i++) {
                                sum += arrHeight[i].height;
                            }

                            sum += (arrHeight[idx].height / 2);

                            thumb.style.top = `calc(var(--scrollW)/ 3 + ${sum}px)`;
                            thumb.style.transform = ``;
                            break;
                        }
                    }
                }
            }, 100)
        })
    })
}