// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started

declare var gsap : any;;
declare var window: any;

function gsapInit() {
	if (document.querySelector("#index-1")) {
    let fullBraney = <HTMLElement>document.querySelector(".full");
    let checked = false;
    fullBraney.style.animation = "dash 4s linear forwards";
    gsap.to(".full", { opacity: 0, duration: 1.2, delay: 3 });
    gsap.to(".half", { opacity: 1, duration: 1.2, delay: 3 });
    gsap.fromTo(
      ".mark",
      { opacity: 0, top: 120 },
      { opacity: 1, top: 0, duration: 4.2 },
    );
    gsap.fromTo(
      ".textDesc",
      { opacity: 0, rotation: 35, x: 160 },
      { opacity: 1, rotation: 0, x: 0, duration: 1.2, delay: 3 },
    );
  
    window.runNumberJs("dataConfirmed", 300);
	}
  // else if (document.querySelector('#projectPage')){  
  //   let title = document.querySelector('.p-1-relate .braney-title')
  //   gsap.from(title, {
  //       opacity: 0,
  //       x: -200,
  //       scrollTrigger: {
  //           trigger: title,
  //           toggleActions: "play none none none", start: "top bottom", end: "bottom top"
  //       },
  //       duration: 1.2,
  //   })

  //   let desc = document.querySelector('.p-1-relate .braney-desc')
  //   gsap.from(desc, {
  //       opacity: 0,
  //       x: -200,
  //       scrollTrigger: {
  //           trigger: desc,
  //           toggleActions: "play none none none", start: "top bottom", end: "bottom top"
  //       },
  //       duration: 1.2,
  //   })

  //   let navbar = document.querySelector('.p-1-relate .nav-bar')
  //   gsap.from(navbar, {
  //       opacity: 0,
  //       x: 200,
  //       scrollTrigger: {
  //           trigger: navbar,
  //           toggleActions: "play none none none", start: "top bottom", end: "bottom top"
  //       },
  //       duration: 1.2,
  //   })

  //   let wrapper = document.querySelector('.p-1-wrapper')
  //   gsap.from(wrapper, {
  //       opacity: 0,
  //       y: 200,
  //       scrollTrigger: {
  //           trigger: wrapper,
  //           toggleActions: "play none none none", start: "top bottom", end: "bottom top"
  //       },
  //       duration: 1.2,
  //   })
  // }
  // else if (document.querySelector('#aboutPage')){

  // }
}

import {initGsap} from './gsapLib/index';

let loading = document.getElementById('loading');
document.addEventListener('readystatechange', (event) => {
  if (document.readyState === "interactive"){
    if (!loading){
      let body = document.querySelector('body');
      loading = document.createElement('div');
      loading.id = "loading";
      body.appendChild(loading);
    }

    loading.style.display = "block";
    let htmlLogo = `
    <div class="thumbnail-bg"> <svg width="3945" height="3945" viewBox="0 0 3945 3945" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.08" clip-path="url(#clip0_902_204)">
      <path d="M2597.46 3062.97C2700.79 2941.05 2779.06 2799.95 2827.81 2647.71C2876.56 2495.48 2894.83 2335.11 2881.57 2175.76C2868.31 2016.41 2823.78 1861.2 2750.54 1719.01C2677.29 1576.82 2576.76 1450.42 2454.68 1347.05C2392.81 1295.09 2325.28 1250.27 2253.37 1213.45C2260.14 1206.68 2266.63 1200.19 2271.99 1192L2547.06 916.929C2624.89 838.207 2668.44 731.898 2668.23 621.146C2668.02 510.394 2624.06 404.168 2545.94 325.596L2517.7 297.357C2438.98 219.085 2332.51 175.12 2221.55 175.068C2110.59 175.016 2004.16 218.882 1925.52 297.08L1642.54 580.054C1637.28 584.754 1632.29 589.747 1627.59 595.007L771.336 1451.26C759.769 1462.83 748.202 1474.4 738.612 1487.94C588.948 1664.69 492.833 1880.51 461.544 2110.07C430.255 2339.63 465.089 2573.41 561.959 2783.99L961.297 2660.27C891.38 2514.45 875.267 2348.64 915.807 2192.19C956.347 2035.74 1050.93 1898.71 1182.82 1805.36C1314.71 1712.01 1475.41 1668.34 1636.5 1682.08C1797.58 1695.83 1948.67 1766.1 2063.03 1880.46C2177.39 1994.82 2247.66 2145.91 2261.41 2306.99C2275.15 2468.08 2231.48 2628.78 2138.13 2760.67C2044.78 2892.56 1907.75 2987.14 1751.3 3027.68C1594.85 3068.22 1429.04 3052.11 1283.22 2982.19L1159.22 3381.25C1400.96 3492.54 1672.42 3521.68 1932.13 3464.21C2191.84 3406.74 2425.51 3265.81 2597.46 3062.97Z" fill="url(#paint0_linear_902_204)"/>
      <path d="M2063.72 1879.77C2160.73 1978.08 2217.95 2108.79 2224.36 2246.7C2230.77 2384.61 2185.9 2519.96 2098.41 2626.67C2010.92 2733.38 1887 2803.89 1750.54 2824.62C1614.07 2845.34 1474.71 2814.82 1359.32 2738.92L1285.48 2982.19C1431.33 3052.26 1597.22 3068.48 1753.77 3027.98C1910.33 2987.48 2047.45 2892.87 2140.85 2760.91C2234.26 2628.95 2277.92 2468.15 2264.12 2306.98C2250.31 2145.81 2179.92 1994.68 2065.41 1880.34L2063.72 1879.77Z" fill="url(#paint1_linear_902_204)"/>
      <path d="M1094.72 1879.04C994.508 1979.28 927.887 2108.17 904.041 2247.94C880.195 2387.71 900.297 2531.48 961.577 2659.42L1204.85 2585.58C1128.94 2470.16 1098.42 2330.77 1119.18 2194.27C1139.93 2057.78 1210.5 1933.84 1317.26 1846.37C1424.03 1758.9 1559.43 1714.09 1697.37 1720.57C1835.31 1727.05 1966.01 1784.36 2064.28 1881.46C2000.76 1817.57 1925.27 1766.82 1842.14 1732.11C1759.01 1697.41 1669.87 1679.42 1579.82 1679.2C1489.76 1678.97 1400.56 1696.51 1317.33 1730.8C1234.09 1765.09 1158.44 1815.46 1094.72 1879.04V1879.04Z" fill="url(#paint2_linear_902_204)"/>
    </g>
    <g opacity="0.08" clip-path="url(#clip1_902_204)">
      <path d="M1976.44 2556.85C2014.76 2511.64 2043.79 2459.31 2061.87 2402.86C2079.94 2346.41 2086.72 2286.93 2081.8 2227.84C2076.88 2168.75 2060.37 2111.19 2033.21 2058.46C2006.05 2005.73 1968.77 1958.86 1923.5 1920.53C1900.55 1901.26 1875.51 1884.64 1848.84 1870.99C1851.36 1868.47 1853.76 1866.07 1855.75 1863.03L1957.75 1761.03C1986.62 1731.83 2002.77 1692.41 2002.69 1651.34C2002.61 1610.27 1986.31 1570.88 1957.34 1541.74L1946.87 1531.27C1917.67 1502.24 1878.19 1485.94 1837.05 1485.92C1795.9 1485.9 1756.43 1502.17 1727.27 1531.17L1622.33 1636.1C1620.38 1637.85 1618.53 1639.7 1616.79 1641.65L1299.26 1959.17C1294.97 1963.46 1290.68 1967.75 1287.12 1972.78C1231.62 2038.32 1195.98 2118.35 1184.38 2203.48C1172.77 2288.61 1185.69 2375.3 1221.61 2453.39L1369.7 2407.51C1343.78 2353.44 1337.8 2291.95 1352.83 2233.93C1367.87 2175.92 1402.94 2125.1 1451.85 2090.49C1500.76 2055.87 1560.35 2039.67 1620.09 2044.77C1679.82 2049.87 1735.85 2075.93 1778.26 2118.33C1820.67 2160.74 1846.73 2216.77 1851.82 2276.51C1856.92 2336.24 1840.73 2395.84 1806.11 2444.75C1771.49 2493.66 1720.68 2528.73 1662.66 2543.76C1604.64 2558.8 1543.16 2552.82 1489.08 2526.89L1443.1 2674.88C1532.75 2716.15 1633.41 2726.95 1729.72 2705.64C1826.03 2684.33 1912.68 2632.07 1976.44 2556.85Z" fill="url(#paint3_linear_902_204)"/>
      <path d="M1778.52 2118.08C1814.49 2154.54 1835.71 2203.01 1838.09 2254.15C1840.46 2305.29 1823.83 2355.48 1791.38 2395.05C1758.94 2434.62 1712.99 2460.77 1662.38 2468.46C1611.77 2476.15 1560.09 2464.83 1517.3 2436.68L1489.92 2526.89C1544.01 2552.88 1605.52 2558.89 1663.58 2543.87C1721.63 2528.85 1772.48 2493.77 1807.12 2444.83C1841.76 2395.9 1857.95 2336.27 1852.83 2276.5C1847.71 2216.74 1821.61 2160.69 1779.15 2118.29L1778.52 2118.08Z" fill="url(#paint4_linear_902_204)"/>
      <path d="M1419.18 2117.81C1382.02 2154.98 1357.31 2202.78 1348.47 2254.61C1339.63 2306.44 1347.08 2359.75 1369.81 2407.2L1460.02 2379.82C1431.87 2337.02 1420.55 2285.32 1428.25 2234.71C1435.95 2184.09 1462.11 2138.13 1501.71 2105.7C1541.3 2073.26 1591.51 2056.64 1642.66 2059.04C1693.82 2061.45 1742.29 2082.7 1778.73 2118.71C1755.17 2095.01 1727.18 2076.19 1696.35 2063.32C1665.52 2050.45 1632.47 2043.79 1599.07 2043.7C1565.68 2043.62 1532.6 2050.12 1501.73 2062.84C1470.86 2075.55 1442.81 2094.23 1419.18 2117.81V2117.81Z" fill="url(#paint5_linear_902_204)"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_902_204" x1="921.669" y1="3022.39" x2="2860.45" y2="1083.61" gradientUnits="userSpaceOnUse">
    <stop/>
    <stop offset="0.557292" stop-color="#717171"/>
    <stop offset="1" stop-color="#959595"/>
    </linearGradient>
    <linearGradient id="paint1_linear_902_204" x1="2144.22" y1="2072.67" x2="1024.25" y2="3210.51" gradientUnits="userSpaceOnUse">
    <stop offset="0.0595484" stop-color="#262626"/>
    <stop offset="1" stop-color="#C6C6C6"/>
    </linearGradient>
    <linearGradient id="paint2_linear_902_204" x1="1450.71" y1="2007.63" x2="976.736" y2="2481.6" gradientUnits="userSpaceOnUse">
    <stop stop-color="#0A0A0A"/>
    <stop offset="1" stop-color="#858585"/>
    </linearGradient>
    <linearGradient id="paint3_linear_902_204" x1="1355.01" y1="2541.8" x2="2073.97" y2="1822.84" gradientUnits="userSpaceOnUse">
    <stop/>
    <stop offset="0.557292" stop-color="#717171"/>
    <stop offset="1" stop-color="#959595"/>
    </linearGradient>
    <linearGradient id="paint4_linear_902_204" x1="1808.37" y1="2189.61" x2="1393.05" y2="2611.56" gradientUnits="userSpaceOnUse">
    <stop offset="0.0595484" stop-color="#262626"/>
    <stop offset="1" stop-color="#C6C6C6"/>
    </linearGradient>
    <linearGradient id="paint5_linear_902_204" x1="1551.19" y1="2165.49" x2="1375.43" y2="2341.26" gradientUnits="userSpaceOnUse">
    <stop stop-color="#0A0A0A"/>
    <stop offset="1" stop-color="#858585"/>
    </linearGradient>
    <clipPath id="clip0_902_204">
    <rect width="2435.31" height="3142.43" fill="white" transform="translate(2222.03) rotate(45)"/>
    </clipPath>
    <clipPath id="clip1_902_204">
    <rect width="903.091" height="1165.31" fill="white" transform="translate(1837.22 1421) rotate(45)"/>
    </clipPath>
    </defs>
    </svg>
          </div>
    `;
    let htmlText = `
    <div class="text"> <svg width="732" height="190" viewBox="0 0 732 190" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M111.861 105.778C111.861 119.949 107.217 130.978 97.9284 138.865C90.912 144.881 83.1272 148.725 74.5739 150.396C71.0323 151.131 66.8893 151.599 62.1449 151.799C60.2071 151.866 57.9685 151.933 55.4293 152L0 152L0 0L46.8092 0C52.6895 0 57.1667 0.100264 60.2405 0.300792C65.1185 0.568162 69.3952 1.13632 73.0704 2.00528C82.1583 4.0774 89.609 8.35532 95.4225 14.839C101.437 21.4565 104.444 29.8118 104.444 39.905C104.444 53.6746 98.5632 63.8681 86.8024 70.4855C103.508 76.234 111.861 87.9982 111.861 105.778ZM67.3571 45.6201C67.3571 41.2084 65.8202 38.1003 62.7463 36.2955C60.3407 34.825 56.7991 34.0897 52.1216 34.0897L37.1867 34.0897L37.1867 57.752L51.9211 57.752C62.2118 57.752 67.3571 53.708 67.3571 45.6201ZM74.6741 103.372C74.6741 98.3588 72.8031 94.7828 69.0611 92.6438C66.1209 91.0396 61.9779 90.2375 56.6321 90.2375L37.1867 90.2375L37.1867 117.91L56.4316 117.91C61.911 117.91 66.1543 116.908 69.1613 114.902C72.8365 112.429 74.6741 108.586 74.6741 103.372Z" fill="#AEAEAE"/>
    <path d="M203.596 68.38C194.842 68.6473 188.494 69.9842 184.552 72.3905C179.339 75.5989 176.733 81.2471 176.733 89.3351L176.733 152L142.654 152L142.654 38L174.628 38L174.628 50.1319L175.13 50.1319C180.542 39.4371 190.031 34.0897 203.596 34.0897L203.596 68.38Z" fill="#AEAEAE"/>
    <path d="M314.476 152L314.476 139.668H314.075C311.201 145.149 306.424 149.326 299.741 152.201C293.995 154.674 287.68 155.91 280.797 155.91C263.624 155.91 249.691 149.995 239 138.164C228.709 126.667 223.564 112.262 223.564 94.9499C223.564 77.905 228.809 63.6007 239.3 52.0369C250.192 40.0721 264.025 34.0897 280.797 34.0897C287.613 34.0897 293.894 35.3597 299.641 37.8997C306.056 40.7071 310.867 44.7845 314.075 50.1319H314.476L314.476 38L348.555 38L348.555 152L314.476 152ZM314.476 94.9499C314.476 86.5946 311.769 79.6763 306.357 74.1953C300.944 68.7142 294.095 65.9736 285.809 65.9736C277.59 65.9736 270.807 68.8479 265.461 74.5963C260.249 80.1442 257.643 87.0624 257.643 95.3509C257.643 103.305 260.349 110.056 265.762 115.604C271.308 121.219 278.057 124.026 286.009 124.026C294.228 124.026 301.044 121.252 306.457 115.704C311.803 110.157 314.476 103.238 314.476 94.9499Z" fill="#AEAEAE"/>
    <path d="M442.983 152L442.983 89.7361C442.983 82.3166 441.914 76.8021 439.776 73.1926C437.036 68.5136 432.158 66.1741 425.142 66.1741C417.724 66.1741 412.479 68.4468 409.405 72.9921C406.866 76.7353 405.596 82.4169 405.596 90.0369L405.596 152L371.517 152L371.517 38L403.291 38L403.291 50.1319H403.792C410.34 39.4371 420.664 34.0897 434.764 34.0897C446.324 34.0897 456.047 37.3984 463.932 44.0158C468.877 48.1601 472.419 53.7414 474.557 60.7599C476.227 66.241 477.063 72.8918 477.063 80.7124L477.063 152L442.983 152Z" fill="#AEAEAE"/>
    <path d="M615.409 96.3536C615.409 99.6288 615.141 102.637 614.607 105.377L529.208 105.377C531.346 119.748 540.233 126.934 555.87 126.934C564.022 126.934 570.938 123.391 576.618 116.306L611.499 116.306C606.421 128.137 599.137 137.562 589.649 144.58C579.558 152 568.299 155.71 555.87 155.71C539.231 155.71 525.031 149.794 513.27 137.963C501.443 126.199 495.529 111.995 495.529 95.3509C495.529 78.6403 501.309 64.3026 512.869 52.3377C524.563 40.1724 538.696 34.0897 555.268 34.0897C572.509 34.0897 586.909 40.1724 598.469 52.3377C609.762 64.3026 615.409 78.9745 615.409 96.3536ZM581.329 82.4169C578.523 69.3826 569.902 62.8654 555.469 62.8654C541.035 62.8654 532.415 69.3826 529.608 82.4169L581.329 82.4169Z" fill="#AEAEAE"/>
    <path d="M674.967 190L638.883 190L656.925 146.285L614.626 38L650.911 38L673.764 104.174L674.165 104.174L695.916 38L732 38L674.967 190Z" fill="#AEAEAE"/>
    </svg>
          </div>
    `;
    loading.innerHTML = htmlLogo + htmlText;
  }
  else if (document.readyState === "complete"){
    loading.style.animation = 'hideLoading 1s forwards linear';
    setTimeout(function(){
      loading.style.display = "none";
      loading.remove();
      // gsapInit();

      initGsap();
    }, 400)
  }
});

declare var ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);

// Fancybox.defaults.ScrollLock = false;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
import {_base} from "./libraries/_base";

import {_imgClass} from "./libraries/_imgClass";
import {initPage} from "./libraries/pagination";
import {gsapScroll} from "./libraries/gsapScroll";

import {boxProcedure} from "./components/_box-procedure";

// import {addHomeScreen} from "./libraries/_addHomeScreen";

// Layout

import {header} from "./layout/header";

// Page
import {index} from "./pages/index";
import {services} from "./pages/services";
import {news} from "./pages/news";
import {about} from "./pages/about";
import {project} from "./pages/project";

//////////////////////////////////////////////////////////////
window.addEventListener('DOMContentLoaded', function() {
  // lib
  _base();
  _imgClass();
  initPage();
  // addHomeScreen();

  // components
  boxProcedure();

  // layout
  header();

  // ----- Page in here ----- //
  index();  
  services();
  news();
  about();
  project();
  gsapScroll();
})



/////////////////////WEB FULLPAGE///////////////////////////
// import loadingAni from "./initAnimation/loading";

// const fullpageLoad = function(){
//   let loading = document.querySelector('#loading-container');
//   loadingAni.aniIn();
//   // import animateFullpage from "./components/animateFullpage";
//   // animateFullpage.default();
  
//   window.addEventListener('load', function() {
//     // swiper_nav();
//     allJs();
//     setTimeout(function(){
//       loading.classList.add('hiddenLoading');
//       setTimeout(function(){
//         loading.parentElement.removeChild(loading);

//         // let utiSubMap = document.getElementById('UtilitiesSubPage');
//         // if (utiSubMap){
//         //   let map = utiSubMap.querySelector('.map');
//         //   map.classList.add('animateActive');
//         // }
//       }, 700)
//       common();
//     }, loadingAni.delay)
//   })
// }

// fullpageLoad();

/////////////////////WEB NORMAL///////////////////////////

