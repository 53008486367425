declare var window: any;
declare var gsap : any;
declare var ScrollTrigger: any;
gsap.registerPlugin(ScrollTrigger);

export const homepage = function(){
    handleIdx1();
}

const handleIdx1 = function(){
    if (document.querySelector("#index-1")) {
        let fullBraney = <HTMLElement>document.querySelector(".full");
        let checked = false;
        fullBraney.style.animation = "dash 4s linear forwards";
        gsap.to(".full", { opacity: 0, duration: 1.2, delay: 3 });
        gsap.to(".half", { opacity: 1, duration: 1.2, delay: 3 });
        gsap.fromTo(
          ".mark",
          { opacity: 0, top: 120 },
          { opacity: 1, top: 0, duration: 4.2 },
        );
        gsap.fromTo(
          ".textDesc",
          { opacity: 0, rotation: 35, x: 160 },
          { opacity: 1, rotation: 0, x: 0, duration: 1.2, delay: 3 },
        );
      
        window.runNumberJs("dataConfirmed", 300);
    }
}

const handleIdx2 = function(){
    
}